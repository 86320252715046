<template>
  <div class="py-5">
    <div class="pb-5 my-4">
      <b-card-title class="pb-2 mb-0 text-med-18 text-font-main">
        {{ $t('common.reset_code') }}
      </b-card-title>
      <b-card-text class="mt-2 text-reg-14 text-font-secondary">
        {{ $t('common.enter_submitted_code') }}
      </b-card-text>
    </div>
    <!-- email -->
    <div class="pt-1" style="width: max-content">
      <wameed-verification-input
          :dir="'ltr'"
          ref="code"
          :label="$t('common.verification_code')"
          :fields="6"
          @change="onCodeChange"
          @complete="onCodeChange"
      />

      <!-- submit buttons -->
      <wameed-btn
          :title="$t('common.send')"
          block
          :disabled="form.code && form.code.length !== 6"
          variant="main"
          :classes="' text-reg-14 text-white d-flex align-items-center rounded-10  justify-content-center mt-2 mb-4'"
          @onClick="submitCode"
      />
      <div class="d-flex flex-column justify-content-center align-items-center">
        <span class="text-reg-14 text-font-secondary mx-2 my-2">
          {{ $t('common.nothing_arrived') }}
          <wameed-counter :end="end" @onFinish="allowResend"/>
        </span>

        <wameed-btn
            :title="$t('common.re_send')"
            variant="link"
            :classes="`px-0 text-reg-14 ${
            shouldResend ? 'text-main' : 'text-font-secondary text-line-through'
          }  my-1`"
            @onClick=" resend"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {WameedBtn,WameedVerificationInput,WameedCounter} from 'wameed-ui/dist/wameed-ui.esm'; 

export default {
  components: {
    WameedBtn,
    WameedCounter,
    WameedVerificationInput,
  },
  data() {
    return {
      shouldResend: true,
      end: 60 * 3, // in seconds

      form: {
        code: null
      }

    };
  },

  methods: {
    submitCode() {
      this.$store.dispatch('auth/confirmCode', this.form);
    },

    onCodeChange(value) {
      this.form.code = value;
    },
    allowResend(action = true, time = 0) {
      this.shouldResend = action;
      this.end = time;
    },
    resend() {
      if (this.shouldResend) {
        this.$store.dispatch('auth/resendCode').then(() => {
          this.allowResend(false, 60 * 3);
        });
      }

    },
  },
};
</script>
